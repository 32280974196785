import { get }                    from '@rails/request.js'
import $fq                        from '../../support/fake_query'
import BmiCalculator              from '../../support/bmi_calculator'
import ConsultationNoteController from '../consultation_note_controller'

export default class extends ConsultationNoteController {

  static targets = this.targets.concat([
    'bmi',
    'editFormContainer',
    'height',
    'hivConfirmationSection',
    'outOfRangeGlyph',
    'outOfRangeGlyphBloodPressureDiastolic',
    'outOfRangeGlyphBloodPressureSystolic',
    'outOfRangeGlyphHba1c',
    'outOfRangeGlyphHemoglobinLevel',
    'outOfRangeGlyphMidUpperArmCircumference',
    'outOfRangeGlyphOxygenSaturation',
    'outOfRangeGlyphPSA',
    'outOfRangeGlyphPulse',
    'outOfRangeGlyphRandomGlucose',
    'outOfRangeGlyphTemperature',
    'outOfRangeGlyphUricAcid',
    'outOfRangeGlyphUrineBilirubin',
    'outOfRangeGlyphUrineBlood',
    'outOfRangeGlyphUrineGlucose',
    'outOfRangeGlyphUrineKeytones',
    'outOfRangeGlyphUrineLeucocytes',
    'outOfRangeGlyphUrineProtein',
    'outOfRangePopover',
    'outOfRangePopoverBloodPressureSystolic',
    'outOfRangePopoverHba1c',
    'outOfRangePopoverHemoglobinLevel',
    'outOfRangePopoverMidUpperArmCircumference',
    'outOfRangePopoverOxygenSaturation',
    'outOfRangePopoverPSA',
    'outOfRangePopoverPulse',
    'outOfRangePopoverRandomGlucose',
    'outOfRangePopoverTemperature',
    'outOfRangePopoverUricAcid',
    'outOfRangePopoverUrineBilirubin',
    'outOfRangePopoverUrineBlood',
    'outOfRangePopoverUrineGlucose',
    'outOfRangePopoverUrineKeytones',
    'outOfRangePopoverUrineLeucocytes',
    'outOfRangePopoverUrineProtein',
    'testStripDetails',
    'weight'
  ])

  static values = {
    rangeCheckUrl: String,
    showTestStrip: Boolean
  }

  static classes = [
    'hideTestStrip'
  ]

  initialize() {
    super.initialize()
  }

  connect() {
    BmiCalculator(this)
  }

  // ==== Controllers

  // ==== Actions

  async checkRange(event) {
    const element = event.currentTarget

    if (element.value === element.dataset.capturedValue) return

    const queryData = new FormData()

    queryData.append(element.name, element.value)

    const response = await get(
      this.rangeCheckUrlValue,
      {
        query:        queryData,
        responseKind: 'turbo-stream'
      }
    )

    if (response.ok) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (!$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })
    }
  }

  toggleOutOfRangePopoverBloodPressureDiastolic() {
    // This must open the popover target for `blood_pressure_systolic`
    this.toggleOutOfRangePopover({ targetStr: 'BloodPressureSystolic' })
  }

  toggleOutOfRangePopoverBloodPressureSystolic() {
    this.toggleOutOfRangePopover({ targetStr: 'BloodPressureSystolic' })
  }

  toggleOutOfRangePopoverMidUpperArmCircumference() {
    this.toggleOutOfRangePopover({ targetStr: 'MidUpperArmCircumference' })
  }

  toggleOutOfRangePopoverOxygenSaturation() {
    this.toggleOutOfRangePopover({ targetStr: 'OxygenSaturation' })
  }

  toggleOutOfRangePopoverHba1c() {
    this.toggleOutOfRangePopover({ targetStr: 'Hba1c' })
  }

  toggleOutOfRangePopoverHemoglobinLevel() {
    this.toggleOutOfRangePopover({ targetStr: 'HemoglobinLevel' })
  }

  toggleOutOfRangePopoverPSA() {
    this.toggleOutOfRangePopover({ targetStr: 'PSA' })
  }

  toggleOutOfRangePopoverPulse() {
    this.toggleOutOfRangePopover({ targetStr: 'Pulse' })
  }

  toggleOutOfRangePopoverRandomGlucose() {
    this.toggleOutOfRangePopover({ targetStr: 'RandomGlucose' })
  }

  toggleOutOfRangePopoverTemperature() {
    this.toggleOutOfRangePopover({ targetStr: 'Temperature' })
  }

  toggleOutOfRangePopoverUricAcid() {
    this.toggleOutOfRangePopover({ targetStr: 'UricAcid' })
  }

  toggleOutOfRangePopoverUrineBlood() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineBlood' })
  }

  toggleOutOfRangePopoverUrineGlucose() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineGlucose' })
  }

  toggleOutOfRangePopoverUrineLeucocytes() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineLeucocytes' })
  }

  toggleOutOfRangePopoverUrineProtein() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineProtein' })
  }

  toggleOutOfRangePopoverUrineKeytones() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineKeytones' })
  }

  toggleOutOfRangePopoverUrineBilirubin() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineBilirubin' })
  }

  togglePrint(event) {
    const element = event.currentTarget

    if (element.innerHTML === '') {
      $fq(this.editFormContainerTarget).removeClass('u-hide')
      this.autoSaveEnabledValue = true
    } else {
      $fq(this.editFormContainerTarget).addClass('u-hide')
      this.autoSaveEnabledValue = false
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  toggleTestStrip(event) {
    const element = event.currentTarget
    this.showTestStripValue = element.checked
  }

  toggleHivSelections(event) {
    const value = event.currentTarget.value

    if (value === '3' || value === '1') {
      $fq(this.hivConfirmationSectionTarget).removeClass('u-hide')
    } else {
      $fq(this.hivConfirmationSectionTarget).addClass('u-hide')
    }
  }

  toggleOutOfRangePopover({ targetStr } = {}) {
    const popover = this[`outOfRangePopover${targetStr}Target`]

    if ($fq(popover).hasClass('u-hide')) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (elPopover !== popover && !$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })

      if (targetStr === 'BloodPressureSystolic') {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureSystolic' })
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureDiastolic' })
      } else {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr })
      }

      $fq(popover).show()
    } else {
      if (targetStr === 'BloodPressureSystolic') {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr: 'BloodPressureSystolic' })
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr: 'BloodPressureDiastolic' })
      } else {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr })
      }

      $fq(popover).hide()
    }
  }

  toggleOutOfRangeGlyph({ glyphStr, targetStr } = {}) {
    const glyph = this[`outOfRangeGlyph${targetStr}Target`]

    $fq(glyph).changeGlyph(glyphStr)
  }

  // ==== Channels

  // ==== Callbacks

  showTestStripValueChanged() {
    if (this.showTestStripValue) {
      $fq(this.testStripDetailsTarget).removeClass(this.hideTestStripClass)
    } else {
      $fq(this.testStripDetailsTarget).addClass(this.hideTestStripClass)
    }
  }

}
