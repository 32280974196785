import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static targets = [
    'callDoctor',
    'callInfo',
    'callStatus',
    'callStatusDoctor',
    'confirmCallType',
    'nurseReviewPath',
    'player',
    'reloadButton'
  ]

  static outlets = [
    'twilio-video'
  ]

  connect() {
    this.fetchJSON()
    this.createVideomedCallChannel()
  }

  // ==== Actions

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  get currentUserId() {
    return this.data.get('currentUserId')
  }

  get currentUserType() {
    return this.data.get('currentUserType')
  }

  get videomedCallId() {
    return this.data.get('videomedCallId')
  }

  get videomedCallStatus() {
    return this.data.get('videomedCallStatus')
  }

  get videomedCallGreetingComplete() {
    return this.data.get('videomedCallGreetingComplete')
  }

  // ==== Setters

  set videomedCallStatus(value) {
    this.data.set('videomedCallStatus', value)
  }

  set videomedCallGreetingComplete(value) {
    this.data.set('videomedCallGreetingComplete', value)
  }

  // ==== Private

  handleStatus(html) {
    if (this.hasCallStatusTarget) {
      this.callStatusTarget.innerHTML = html
    }
  }

  handleDoctorStatus(html) {
    if (this.hasCallStatusDoctorTarget) {
      this.callStatusDoctorTarget.innerHTML = html
    }
  }

  fetchJSON() {
    if (this.videomedCallStatus === 'ringing') {
      const self = this
      const url  = `/api/v1/videomed-calls/${this.videomedCallId}`

      fetch(url, { method: 'GET' })
        .then((resp) => resp.json())
        .then((json) => {
          const status = json.data.attributes.status

          self.updateVideomedCallStatus(status)

          if (status === 'answered') {
            self.handleStatus('')
            self.handleDoctorStatus('')
          }
        })
        .catch((_error) => {})
    }
  }

  updateVideomedCallStatus(status) {
    this.videomedCallStatus = status

    if (this.hasTwilioVideoOutlet) {
      this.twilioVideoOutlet.videomedCallStatus = status
    }
  }

  updateVideomedCallGreetingComplete(value) {
    this.videomedCallGreetingComplete = value

    if (this.hasTwilioVideoOutlet) {
      this.twilioVideoOutlet.videomedCallGreetingComplete = value
    }
  }

  // ==== Channels

  createVideomedCallChannel = async () => {
    const self = this

    await CreateChannel({
      channel:          'VideomedCallChannel',
      consultation_id:  self.consultationId,
      videomed_call_id: self.videomedCallId
    }, {
      received(data) {
        if (self.hasCallInfoTarget) {
          self.callInfoTarget.innerHTML = data.info_html
        }

        self.updateVideomedCallStatus(data.status)
        self.updateVideomedCallGreetingComplete(data.greeting_complete)

        switch (data.status) {
          case 'greeting':
            self.handleStatus(data.status_html)
            break

          case 'answered':
            self.handleStatus('')
            self.handleDoctorStatus('')
            break

          case 'on_hold':
            self.handleStatus(data.status_html)
            self.handleDoctorStatus(data.doctor_status_html)
            break

          case 'reload':
            self.callStatusTarget.insertAdjacentHTML('beforeEnd', data.reload_button_html)
            self.reloadButtonTarget.click()
            break

          case 'ringing':
            self.handleStatus(data.status_html)
            self.handleDoctorStatus(data.doctor_status_html)
            break

          case 'rejected':
            self.handleStatus('')
            self.handleDoctorStatus(data.doctor_status_html)

            if (self.hasCallDoctorTarget) {
              self.callDoctorTarget.innerHTML = data.call_doctor_html
            }
            break

          case 'cancelled':
          case 'closed':
            self.handleStatus('')
            self.handleDoctorStatus(data.doctor_status_html)

            if (self.hasConfirmCallTypeTarget) {
              self.confirmCallTypeTarget.click()
            }

            if (self.hasCallDoctorTarget) {
              self.callDoctorTarget.innerHTML = data.call_doctor_html
            }

            if (self.hasNurseReviewPathTarget) {
              self.nurseReviewPathTarget.click()
            }
            break

          default:
            break
        }
      }
    })
  }

}
