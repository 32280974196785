import moment                from 'moment'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'exceedAverageWaitTime',
    'counter'
  ]

  connect() {
    this.timeInQueue()
  }

  disconnect() {
    clearTimeout(this.counterInterval)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get averageWaitTime() {
    return this.data.get('averageWaitTime')
  }

  get timerStartedAt() {
    return this.data.get('timerStartedAt')
  }

  // ==== Setters

  set averageWaitTime(value) {
    this.data.set('averageWaitTime', value)
  }

  // ==== Private

  hideExceedAverageTime() {
    if (this.hasExceedAverageWaitTimeTarget) {
      this.exceedAverageWaitTimeTarget.classList.add('u-hide')
    }
  }

  showExceedAverageTime() {
    if (this.hasExceedAverageWaitTimeTarget) {
      this.exceedAverageWaitTimeTarget.classList.remove('u-hide')
    }
  }

  timeInQueue() {
    const self  = this

    if (this.hasCounterTarget) {
      const eventTime   = moment(this.timerStartedAt, 'YYYY-MM-DD HH-mm-ss')
      const currentTime = moment()
      const interval    = 1000
      let duration      = moment.duration(currentTime.diff(eventTime, 'ms'))

      this.counterInterval = setInterval(() => {
        duration = moment.duration(duration.asMilliseconds() + interval, 'ms')

        if (duration.asMinutes() > parseInt(this.averageWaitTime, 10)) {
          this.showExceedAverageTime()
        } else {
          this.hideExceedAverageTime()
        }

        const d = `0${duration.days()}`.substr(-2)
        const h = `0${duration.hours()}`.substr(-2)
        const m = `0${duration.minutes()}`.substr(-2)
        const s = `0${duration.seconds()}`.substr(-2)

        self.counterTarget.innerHTML = `${d}:${h}:${m}:${s}`.replace(/^(?:00:)/, '')
      }, interval)
    }
  }

  // ==== Channels

}
