import ApplicationController from '../../support/application_controller'
import BmiCalculator         from '../../support/bmi_calculator'
import WizardMixin           from '../../support/wizard_mixin'

export default class extends WizardMixin(ApplicationController) {

  static targets = [
    'bmi',
    'contraceptiveManagement',
    'height',
    'medicalConditionsNoneInverse',
    'medicationNoneInverse',
    'otherSymptomsNoneOfTheAbove',
    'patientDiabetic',
    'recentProcedure',
    'urinarySymptomsNoneOfTheAbove',
    'urineTestStripNo',
    'urineTestStripYes',
    'weight'
  ]

  static classes = [
    'hide'
  ]

  connect() {
    BmiCalculator(this)
  }

  // ==== Controllers

  // ==== Actions

  toggleCondition(event) {
    this.#conditions().forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleMedicationNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicationNoneInverseTargets
    })
  }

  toggleMedicalConditionsNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicalConditionsNoneInverseTargets
    })
  }

  togglePatientDiabetic(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    }
  }

  toggleRecentProcedure(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    }
  }

  toggleUrineTestStrip(event) {
    [
      { value: 'no', target: this.urineTestStripNoTarget },
      { value: 'yes', target: this.urineTestStripYesTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleUrinarySymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:   currentTarget,
      targets: this.urinarySymptomsNoneOfTheAboveTargets
    })
  }

  toggleOtherSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:   currentTarget,
      targets: this.otherSymptomsNoneOfTheAboveTargets
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  #conditions() {
    const conditions = []

    if (this.hasContraceptiveManagementTarget) {
      conditions.push({ value: 'contraceptive_management', target: this.contraceptiveManagementTarget })
    }

    return conditions
  }

}
